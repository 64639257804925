import { AlertController } from "@ionic/angular";
import { Auth } from "aws-amplify";

export class IxToast {}

const alertController = new AlertController();

export const ixToast = async (message, autoSignout = false) => {
    const signout = () => {
        Auth.signOut().then(() => {
            window.location.href = "/portal";
        });
    };

    const donothing = () => {};

    const alert = await alertController.create({
        cssClass: "alert-custom-class",
        header: "Error",
        // subHeader: 'Subtitle',
        message: message,
        buttons: [
            { text: "Got it", handler: autoSignout ? signout : donothing },
        ],
    });
    await alert.present();
};
